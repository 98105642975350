import React, { useEffect, useState } from "react";
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useIsAuthenticated,
	useMsal,
} from "@azure/msal-react";
import { AuthError } from "@azure/msal-common";
import { Link, Redirect, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { LinearProgress } from "@mui/material";
import { UserTable } from "./UsersTable";
import { ScoreTotals } from "./ScoreTotals";
import { MapContainer } from "./MapContainer";
import {
	useFetchRegionByShortcode,
	useFetchUsers,
	useFetchAdminDashboard,
	PageCacheContext,
} from "../../endpoints";
import { adminRequest, graphConfig } from "../../authConfig";
import { callMsGraph } from "../../graph";
import "../../styles/admin/AdminDashboard.css";

const hqGroupId = "250d62f1-9cde-404b-ad24-4e4a134ae064";
const mentalGroupId = "5876307a-e81c-4b8d-8772-d880ad7af683";
const injazatAdId = "5774259b-7c35-4df0-bf25-3901eb73df2c";
const adminRegionGroupIds = [hqGroupId, mentalGroupId];

export const AdminDashboard = ({ lang }) => {
	const isAuthenticated = useIsAuthenticated();
	const [accessToken, setAccessToken] = useState(null);
	const { instance, accounts } = useMsal();
	const { regionShortcode } = useParams();
	const [selectedRegionID, setSelectedRegionID] = useState(null);
	const authRegion = useFetchRegionByShortcode(regionShortcode);

	const [currentView, setCurrentView] = useState("MVP");
	useEffect(() => {
		if (authRegion?.groupId) {
			const selectedView = (
				authRegion.adId === injazatAdId
				|| adminRegionGroupIds.find((x) => x === authRegion.groupId)) ? "HQ" : "MVP";
			setCurrentView(selectedView);
			setSelectedRegionID(authRegion?.id);
		}
	}, [authRegion]);

	const [redirectTo, setRedirectTo] = useState(null);
	const {
		data,
		fetchingAdminDashboardData,
	} = useFetchAdminDashboard(setRedirectTo, authRegion, selectedRegionID, accessToken);
	const {
		localizedText: text,
		regiondata,
		regionInfo,
		switchRegion,
	} = data;

	const pageSize = 10;
	const [currentPage, setCurrentPage] = useState(0);
	const [searchValue, setsearchValue] = useState("");

	const [
		azureUsers,
		pageCache,
		setPageCache,
	] = useFetchUsers(regionInfo, searchValue, pageSize, currentPage, accessToken, selectedRegionID);

	useEffect(() => {
		const acquireAdminToken = async () => {
			try {
				const tokenResponse = await instance.acquireTokenSilent({
					...adminRequest(authRegion.adId, authRegion.groupId),
					account: accounts[0],
				});
				await checkAndSetAccessToken(tokenResponse, authRegion, setAccessToken);
			} catch (error) {
				if (error instanceof AuthError) {
					try {
						const tokenResponse = await instance.acquireTokenPopup({
							...adminRequest(authRegion.adId, authRegion.groupId),
							account: accounts[0],
						});
						await checkAndSetAccessToken(tokenResponse, authRegion, setAccessToken);
					} catch (error) {
						setRedirectTo("/taqa-h2/no-access");
						return;
					}
				}
				setRedirectTo("/taqa-h2/no-access");
			}
		};

		if (isAuthenticated && authRegion) {
			acquireAdminToken();
		}
	}, [instance, isAuthenticated, authRegion, accounts]);

	const scaleColors = class {
		static return(value) {
			if (value < 25) return "#D26041";
			if (value < 50) return "#EEB64C";
			if (value < 75) return "#F2CF54";
			if (value <= 100) return "#71A450";
			return "#e2e4e9";
		}
	};

	let fallbackRender = (<></>);
	if (redirectTo) {
		fallbackRender = (<Redirect to={redirectTo} />);
	}

	const [textcontent, settextcontent] = useState(null);
	useEffect(() => {
		if (text.text && lang) settextcontent(text.text.content ? text.text.content[lang] : null);
	}, [text, lang]);

	const pageContextValue = makePageCacheContextValue(
		pageCache,
		setPageCache,
		regionInfo,
		accessToken,
	);

	const renderDashboard = currentView === "MVP"
		? (
			<PageCacheContext.Provider value={pageContextValue}>
				<AdminDashboardMVP
					regionStats={switchRegion.regionStats[0]}
					questions={switchRegion.questionStats}
					scaleColors={scaleColors}
					users={azureUsers}
					setsearchValue={setsearchValue}
					setCurrentPage={setCurrentPage}
					textcontent={textcontent}
					integers={text?.integers?.content}
					lang={lang}
					setRedirectTo={setRedirectTo}
					setSelectedRegionID={setSelectedRegionID}
				/>
			</PageCacheContext.Provider>
		)
		: (
			<PageCacheContext.Provider value={pageContextValue}>
				<AdminDashboardHQ
					setSelectedRegionID={setSelectedRegionID}
					selectedRegionID={selectedRegionID}
					regionStats={switchRegion.regionStats[0]}
					questions={switchRegion.questionStats}
					scaleColors={scaleColors}
					users={azureUsers}
					setsearchValue={setsearchValue}
					setCurrentPage={setCurrentPage}
					textcontent={textcontent}
					integers={text?.integers?.content}
					lang={lang}
					regiondata={regiondata}
					setRedirectTo={setRedirectTo}
					authRegion={authRegion}
				/>
			</PageCacheContext.Provider>
		);

	return !authRegion || !accessToken || redirectTo ? fallbackRender : (
		<>
			{fetchingAdminDashboardData ? <LinearProgress /> : renderDashboard}
		</>
	);
};

const AdminDashboardHQ = ({
	setSelectedRegionID,
	selectedRegionID,
	scaleColors,
	users,
	textcontent,
	lang,
	setsearchValue,
	setCurrentPage,
	regiondata,
	mappaths,
	integers,
	setRedirectTo,
	authRegion,
	regionStats,
}) => {
	const [selectedRegion, setSelectedRegion] = useState(null);
	useEffect(() => {
		if (regiondata?.length > 0) {
			const selected = regiondata.find((x) => x.id === selectedRegionID);
			if (selected) {
				setSelectedRegion(selected);
			}
		}
	}, [regiondata, selectedRegionID]);
	return (
		<>
			<AuthenticatedTemplate>
				<div
					className="adminDashboard"
					data-mode="0"
				>
					<br />
					<br />
					<h3 className="adminDashboard_title">
						{textcontent ? textcontent[0] : <></>}
					</h3>
					<Grid container className="adminDashInner">
						<Grid item xs={4}>
							<ScoreTotals
								text={textcontent}
								lang={lang}
								integers={integers}
								regionStats={regionStats}
							/>
							<div style={{ textAlign: "left", marginTop: 5 }}>
								<button onClick={() => setSelectedRegionID(authRegion.id)} className="resetButton" type="button">Default Region</button>
							</div>
						</Grid>
						<Grid item xs={8}>
							<MapContainer
								text={textcontent}
								scaleColors={scaleColors}
								setSelectedRegionID={setSelectedRegionID}
								selectedRegionID={selectedRegionID}
								regiondata={regiondata}
								mappaths={mappaths}
							/>
						</Grid>
						<Grid item xs={12}>
							{selectedRegion && authRegion && selectedRegion.adId !== authRegion.adId
								? (

									<Link
										// eslint-disable-next-line max-len
										to={`/taqa-h2/${selectedRegion?.shortcode || ""}`}
										target="_blank"
									>
										<img className="warningIcon" src="/taqa-h2/image-warning-sign-yellow-stroke-border-35.png" alt="warning" />
										You have selected a user group that is not accessible from your current login.
										<br />
										Use this link to open a new tab and log into
										{" "/* eslint-disable-next-line max-len */}
										{selectedRegion?.shortName || "the correct one"}
										{" "}
										and view the desired results,
										<br />
										<br />
									</Link>
								)
								: (
									<></>
								)}
							<UserTable
								text={textcontent}
								lang={lang}
								integers={integers}
								scaleColors={scaleColors}
								users={users}
								setsearchValue={setsearchValue}
								setCurrentPage={setCurrentPage}
								setRedirectTo={setRedirectTo}
							/>
						</Grid>
					</Grid>
				</div>
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<Redirect to="/" />
			</UnauthenticatedTemplate>
		</>
	);
};

const AdminDashboardMVP = ({
	scaleColors,
	users,
	setsearchValue,
	setCurrentPage,
	textcontent,
	lang,
	setRedirectTo,
	integers,
	regionStats,
}) => (
	<>
		<AuthenticatedTemplate>
			<div className="adminDashboard" data-mode="1">
				<br />
				<br />
				<h3 className="adminDashboard_title">
					{textcontent ? textcontent[0] : <></>}
				</h3>
				<Grid container className="adminDashInner">
					<Grid item xs={12}>
						<ScoreTotals
							text={textcontent}
							lang={lang}
							integers={integers}
							regionStats={regionStats}
						/>
					</Grid>
					<Grid item xs={12}>
						<UserTable
							text={textcontent}
							lang={lang}
							integers={integers}
							scaleColors={scaleColors}
							users={users}
							setsearchValue={setsearchValue}
							setCurrentPage={setCurrentPage}
							setRedirectTo={setRedirectTo}
						/>
					</Grid>
				</Grid>
			</div>
		</AuthenticatedTemplate>

		<UnauthenticatedTemplate>
			<Redirect to="/" />
		</UnauthenticatedTemplate>
	</>
);
function makePageCacheContextValue(pageCache, setPageCache, regionInfo, accessToken) {
	return {
		buffer: pageCache,
		setBuffer: (buffer) => setPageCache(buffer),
		regionInfo,
		accessToken,
	};
}

async function checkAndSetAccessToken(tokenResponse, authRegion, setAccessToken) {
	const query = graphConfig.graphMeOwnedObjects("id");
	const ownedObjectsResponse = await callMsGraph(tokenResponse.accessToken, query);
	const hasAdminAccessToGroup = ownedObjectsResponse.value.find((object) =>
		// eslint-disable-next-line implicit-arrow-linebreak,comma-dangle
		`${object.id}` === authRegion.groupId
		// eslint-disable-next-line function-paren-newline
	);
	if (!hasAdminAccessToGroup) {
		throw new Error("Not the group's owner");
	}
	setAccessToken(tokenResponse.accessToken);
}
