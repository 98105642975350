import React from "react";
import Grid from "@mui/material/Grid";
import "../../styles/dashboard/UserDashboard.css";

const isArabic = (lang) => lang && lang.toString().toLowerCase() === "ar";

export const UserDashboardSurvey = ({
	sessionId,
	setcurrentView,
	setCurrentStep,
	text,
	token,
	lang,
}) => {
	setCurrentStep(2);

	const handleSubmit = (e) => {
		e.preventDefault();
		async function fetchData() {
			const object = {};
			const formData = new FormData(e.target);
			formData.forEach((value, key) => {
				object[key] = value;
			});
			await fetch(`/taqa-h2/api/v2/session/${sessionId}/survey`, {
				method: "PUT",
				headers: { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
				body: JSON.stringify(object),
			});
			setcurrentView("Complete");
		}
		fetchData();
	};

	return (
		<>
			<div className="userDash_topBar">
				<Grid container>
					<Grid item xs={12}>
						<button
							className="userDash_exitLeft"
							type="button"
							onClick={() => setcurrentView("Attestation")}
						>
							<i style={{ backgroundImage: "url(/taqa-h2/arr.png)" }} />
							{text ? text[86] : <></>}
						</button>
					</Grid>
				</Grid>
			</div>
			<div
				className="userDashboard"
				data-mode="0"
				style={
					isArabic(lang) ? {
						direction: "rtl",
						textAlign: "right",
					} : {
						direction: "ltr",
						textAlign: "left",
					}
				}
			>
				<h3 className="userDashboard_title userDash_wide">
					{text ? text[87] : <></>}
				</h3>
				<Grid container className="userDashInner">
					<Grid item xs={12}>
						<form onSubmit={handleSubmit}>
							<div className="userDash_InnerBox">
								<p><b>{text ? text[0] : <></>}</b></p>
								<br />
								<p>{text ? text[1] : <></>}</p>
								<br />
								<p>{text ? text[2] : <></>}</p>
								<p>{text ? text[3] : <></>}</p>
								<p>{text ? text[4] : <></>}</p>
								<br />
								<p>{text ? text[5] : <></>}</p>
								<p>{text ? text[6] : <></>}</p>
								<p>{text ? text[7] : <></>}</p>
							</div>
							<div className="userDash_InnerBox_white">
								<p>{text ? text[8] : <></>}</p>
								<label htmlFor="radio_0">
									<input id="radio_0" name="Question1" type="radio" value={text ? text[9] : <></>} />
									<span>{text ? text[9] : <></>}</span>
								</label>
								<label htmlFor="radio_1">
									<input id="radio_1" name="Question1" type="radio" value={text ? text[10] : <></>} />
									<span>{text ? text[10] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[11] : <></>}</p>
								<label htmlFor="radio_2">
									<input id="radio_2" name="Question2" type="radio" value={text ? text[12] : <></>} />
									<span>{text ? text[12] : <></>}</span>
								</label>
								<label htmlFor="radio_3">
									<input id="radio_3" name="Question2" type="radio" value={text ? text[13] : <></>} />
									<span>{text ? text[13] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[14] : <></>}</p>
								<label htmlFor="radio_4">
									<input id="radio_4" name="Question3" type="radio" value={text ? text[15] : <></>} />
									<span>{text ? text[15] : <></>}</span>
								</label>
								<label htmlFor="radio_5">
									<input id="radio_5" name="Question3" type="radio" value={text ? text[16] : <></>} />
									<span>{text ? text[16] : <></>}</span>
								</label>
								<label htmlFor="radio_6">
									<input id="radio_6" name="Question3" type="radio" value={text ? text[17] : <></>} />
									<span>{text ? text[17] : <></>}</span>
								</label>
								<label htmlFor="radio_7">
									<input id="radio_7" name="Question3" type="radio" value={text ? text[18] : <></>} />
									<span>{text ? text[18] : <></>}</span>
								</label>
								<label htmlFor="radio_8">
									<input id="radio_8" name="Question3" type="radio" value={text ? text[19] : <></>} />
									<span>{text ? text[19] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[20] : <></>}</p>
								<label htmlFor="radio_9">
									<input id="radio_9" name="Question4" type="radio" value={text ? text[21] : <></>} />
									<span>{text ? text[21] : <></>}</span>
								</label>
								<label htmlFor="radio_10">
									<input id="radio_10" name="Question4" type="radio" value={text ? text[22] : <></>} />
									<span>{text ? text[22] : <></>}</span>
								</label>
								<label htmlFor="radio_11">
									<input id="radio_11" name="Question4" type="radio" value={text ? text[23] : <></>} />
									<span>{text ? text[23] : <></>}</span>
								</label>
								<label htmlFor="radio_12">
									<input id="radio_12" name="Question4" type="radio" value={text ? text[24] : <></>} />
									<span>{text ? text[24] : <></>}</span>
								</label>
								<label htmlFor="radio_13">
									<input id="radio_13" name="Question4" type="radio" value={text ? text[25] : <></>} />
									<span>{text ? text[25] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[26] : <></>}</p>
								<label htmlFor="radio_14">
									<input id="radio_14" name="Question5" type="radio" value={text ? text[27] : <></>} />
									<span>{text ? text[27] : <></>}</span>
								</label>
								<label htmlFor="radio_15">
									<input id="radio_15" name="Question5" type="radio" value={text ? text[28] : <></>} />
									<span>{text ? text[28] : <></>}</span>
								</label>
								<label htmlFor="radio_16">
									<input id="radio_16" name="Question5" type="radio" value={text ? text[29] : <></>} />
									<span>{text ? text[29] : <></>}</span>
								</label>
								<label htmlFor="radio_17">
									<input id="radio_17" name="Question5" type="radio" value={text ? text[30] : <></>} />
									<span>{text ? text[30] : <></>}</span>
								</label>
								<label htmlFor="radio_18">
									<input id="radio_18" name="Question5" type="radio" value={text ? text[31] : <></>} />
									<span>{text ? text[31] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[32] : <></>}</p>
								<label htmlFor="radio_19">
									<input id="radio_19" name="Question6" type="radio" value={text ? text[33] : <></>} />
									<span>{text ? text[33] : <></>}</span>
								</label>
								<label htmlFor="radio_20">
									<input id="radio_20" name="Question6" type="radio" value={text ? text[34] : <></>} />
									<span>{text ? text[34] : <></>}</span>
								</label>
								<label htmlFor="radio_21">
									<input id="radio_21" name="Question6" type="radio" value={text ? text[35] : <></>} />
									<span>{text ? text[35] : <></>}</span>
								</label>
								<label htmlFor="radio_22">
									<input id="radio_22" name="Question6" type="radio" value={text ? text[36] : <></>} />
									<span>{text ? text[36] : <></>}</span>
								</label>
								<label htmlFor="radio_23">
									<input id="radio_23" name="Question6" type="radio" value={text ? text[37] : <></>} />
									<span>{text ? text[37] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[38] : <></>}</p>
								<label htmlFor="radio_24">
									<input id="radio_24" name="Question7" type="radio" value={text ? text[39] : <></>} />
									<span>{text ? text[39] : <></>}</span>
								</label>
								<label htmlFor="radio_25">
									<input id="radio_25" name="Question7" type="radio" value={text ? text[40] : <></>} />
									<span>{text ? text[40] : <></>}</span>
								</label>
							</div>
							<div className="userDash_InnerBox">
								<p>{text ? text[41] : <></>}</p>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[42] : <></>}</p>
								<label htmlFor="radio_26">
									<input id="radio_26" name="Question8" type="radio" value={text ? text[43] : <></>} />
									<span>{text ? text[43] : <></>}</span>
								</label>
								<label htmlFor="radio_27">
									<input id="radio_27" name="Question8" type="radio" value={text ? text[44] : <></>} />
									<span>{text ? text[44] : <></>}</span>
								</label>
								<label htmlFor="radio_28">
									<input id="radio_28" name="Question8" type="radio" value={text ? text[45] : <></>} />
									<span>{text ? text[45] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[46] : <></>}</p>
								<label htmlFor="radio_29">
									<input id="radio_29" name="Question9" type="radio" value={text ? text[47] : <></>} />
									<span>{text ? text[47] : <></>}</span>
								</label>
								<label htmlFor="radio_30">
									<input id="radio_30" name="Question9" type="radio" value={text ? text[48] : <></>} />
									<span>{text ? text[48] : <></>}</span>
								</label>
								<label htmlFor="radio_31">
									<input id="radio_31" name="Question9" type="radio" value={text ? text[49] : <></>} />
									<span>{text ? text[49] : <></>}</span>
								</label>
								<label htmlFor="radio_32">
									<input id="radio_32" name="Question9" type="radio" value={text ? text[50] : <></>} />
									<span>{text ? text[50] : <></>}</span>
								</label>
								<label htmlFor="radio_33">
									<input id="radio_33" name="Question9" type="radio" value={text ? text[51] : <></>} />
									<span>{text ? text[51] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[52] : <></>}</p>
								<label htmlFor="radio_34">
									<input id="radio_34" name="Question10" type="radio" value={text ? text[53] : <></>} />
									<span>{text ? text[53] : <></>}</span>
								</label>
								<label htmlFor="radio_35">
									<input id="radio_35" name="Question10" type="radio" value={text ? text[54] : <></>} />
									<span>{text ? text[54] : <></>}</span>
								</label>
								<label htmlFor="radio_36">
									<input id="radio_36" name="Question10" type="radio" value={text ? text[55] : <></>} />
									<span>{text ? text[55] : <></>}</span>
								</label>
								<label htmlFor="radio_37">
									<input id="radio_37" name="Question10" type="radio" value={text ? text[56] : <></>} />
									<span>{text ? text[56] : <></>}</span>
								</label>
								<label htmlFor="radio_38">
									<input id="radio_38" name="Question10" type="radio" value={text ? text[57] : <></>} />
									<span>{text ? text[57] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[58] : <></>}</p>
								<label htmlFor="radio_39">
									<input id="radio_39" name="Question11" type="radio" value={text ? text[59] : <></>} />
									<span>{text ? text[59] : <></>}</span>
								</label>
								<label htmlFor="radio_40">
									<input id="radio_40" name="Question11" type="radio" value={text ? text[60] : <></>} />
									<span>{text ? text[60] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[61] : <></>}</p>
								<label htmlFor="radio_41">
									<input id="radio_41" name="Question12" type="radio" value={text ? text[62] : <></>} />
									<span>{text ? text[62] : <></>}</span>
								</label>
								<label htmlFor="radio_42">
									<input id="radio_42" name="Question12" type="radio" value={text ? text[63] : <></>} />
									<span>{text ? text[63] : <></>}</span>
								</label>
								<label htmlFor="radio_43">
									<input id="radio_43" name="Question12" type="radio" value={text ? text[64] : <></>} />
									<span>{text ? text[64] : <></>}</span>
								</label>
								<label htmlFor="radio_44">
									<input id="radio_44" name="Question12" type="radio" value={text ? text[65] : <></>} />
									<span>{text ? text[65] : <></>}</span>
								</label>
								<label htmlFor="radio_45">
									<input id="radio_45" name="Question12" type="radio" value={text ? text[66] : <></>} />
									<span>{text ? text[66] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[67] : <></>}</p>
								<label htmlFor="radio_46">
									<input id="radio_46" name="Question13" type="radio" value={text ? text[68] : <></>} />
									<span>{text ? text[68] : <></>}</span>
								</label>
								<label htmlFor="radio_47">
									<input id="radio_47" name="Question13" type="radio" value={text ? text[69] : <></>} />
									<span>{text ? text[69] : <></>}</span>
								</label>
								<label htmlFor="radio_48">
									<input id="radio_48" name="Question13" type="radio" value={text ? text[70] : <></>} />
									<span>{text ? text[70] : <></>}</span>
								</label>
								<label htmlFor="radio_49">
									<input id="radio_49" name="Question13" type="radio" value={text ? text[71] : <></>} />
									<span>{text ? text[71] : <></>}</span>
								</label>
								<label htmlFor="radio_50">
									<input id="radio_50" name="Question13" type="radio" value={text ? text[72] : <></>} />
									<span>{text ? text[72] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[73] : <></>}</p>
								<label htmlFor="radio_51">
									<input id="radio_51" name="Question14" type="radio" value={text ? text[74] : <></>} />
									<span>{text ? text[74] : <></>}</span>
								</label>
								<label htmlFor="radio_52">
									<input id="radio_52" name="Question14" type="radio" value={text ? text[75] : <></>} />
									<span>{text ? text[75] : <></>}</span>
								</label>
								<label htmlFor="radio_53">
									<input id="radio_53" name="Question14" type="radio" value={text ? text[76] : <></>} />
									<span>{text ? text[76] : <></>}</span>
								</label>
								<label htmlFor="radio_54">
									<input id="radio_54" name="Question14" type="radio" value={text ? text[77] : <></>} />
									<span>{text ? text[77] : <></>}</span>
								</label>
								<label htmlFor="radio_55">
									<input id="radio_55" name="Question14" type="radio" value={text ? text[78] : <></>} />
									<span>{text ? text[78] : <></>}</span>
								</label>
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[79] : <></>}</p>
								<textarea name="Question15" />
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[80] : <></>}</p>
								<label htmlFor="radio_56">
									<input id="radio_56" name="Question16a" type="radio" value={text ? text[81] : <></>} />
									<span>{text ? text[81] : <></>}</span>
								</label>
								<label htmlFor="radio_57">
									<input id="radio_57" name="Question16a" type="radio" value={text ? text[82] : <></>} />
									<span>{text ? text[82] : <></>}</span>
								</label>
								<p>{text ? text[83] : <></>}</p>
								<textarea name="Question16b" />
							</div>
							<hr />
							<div className="userDash_InnerBox_white">
								<p>{text ? text[84] : <></>}</p>
								<textarea name="Question17" />
							</div>
							<hr />
							<br />
							<button type="submit">{text ? text[85] : <></>}</button>
						</form>
					</Grid>
				</Grid>
			</div>
		</>
	);
};
