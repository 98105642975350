import React from "react";
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Redirect, useParams } from "react-router-dom";
import "../../styles/dashboard/NoAccessDashboard.css";

// eslint-disable-next-line
export const NoAccessDashboard = ({ lang }) => {
	// eslint-disable-next-line
	const { regionShortcode } = useParams();

	return (
		<>
			<AuthenticatedTemplate>
				<h1>NO ACCESS</h1>
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<Redirect to="/taqa-h2/" />
			</UnauthenticatedTemplate>
		</>
	);
};
