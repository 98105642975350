import React, { useState } from "react";
import "../../styles/admin/DateRangePicker.css";

export const DateRangePicker = ({
	lang,
	text,
	integers,
	setFilterValue,
}) => {
	const monthOld = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
	const monthOldDay = new Date(monthOld.toLocaleString("default", { year: "numeric" }), monthOld.getMonth(), monthOld.getDate());
	const weekOld = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
	const weekOldDay = new Date(weekOld.toLocaleString("default", { year: "numeric" }), weekOld.getMonth(), weekOld.getDate());
	const now = new Date(Date.now());
	const today = new Date(now.toLocaleString("default", { year: "numeric" }), now.getMonth(), now.getDate());
	const thisMonth = new Date(now.toLocaleString("default", { year: "numeric" }), now.getMonth());

	const [openPopup, setOpenPopup] = useState("");
	const [dateData, setDateData] = useState([false, false]);
	const [calendarMonths, setCalendarMonths] = useState([monthOldDay, thisMonth]);

	const getDaysInMonth = (month, year) => (new Array(31))
		.fill("")
		.map((v, i) => (new Date(year, month - 1, i + 1)))
		.filter((v) => (v.getMonth() === month - 1));

	const createCalendar = (side) => (
		<div className="dateRange_section">
			{getDaysInMonth(
				calendarMonths[side].getMonth() + 1,
				calendarMonths[side].toLocaleString("default", { year: "numeric" }),
			).map((day, index) => {
				const daysHTML = [];
				if (index === 0) {
					let i = 0;
					while (i < day.getDay()) {
						daysHTML[i] = <div key={i} className="dateRange_day" />;
						i += 1;
					}
				}

				let dayText = day.getDate().toString();
				if (lang && integers) integers[lang].forEach((i) => { dayText = dayText.replaceAll(i[0], i[1]); });

				daysHTML.push(
					<button
						key={day.getTime()}
						data-disablea={
							dateData[1] && side === 0 && day.getTime() >= dateData[1].getTime()
						}
						data-disableb={
							dateData[0] && side === 1 && day.getTime() <= dateData[0].getTime()
						}
						data-date={day.toString()}
						data-between={
							dateData[0]
							&& dateData[1]
							&& day.getTime() > dateData[0].getTime()
							&& day.getTime() < dateData[1].getTime()
						}
						data-selected={dateData[side] && day.getTime() === dateData[side].getTime()}
						data-grayselected={dateData[1 - side] && day.getTime() === dateData[1 - side].getTime()}
						type="button"
						className="dateRange_day"
						onClick={() => {
							const newDateData = [];
							newDateData[1 - side] = dateData[1 - side];
							newDateData[side] = day;
							setDateData(newDateData);
						}}
					>
						{dayText}
					</button>,
				);
				return daysHTML;
			})}
		</div>
	);

	const monthPicker = (side) => {
		let yearText = calendarMonths[side].toLocaleString("default", { year: "numeric" });
		if (lang && integers) integers[lang].forEach((i) => { yearText = yearText.replaceAll(i[0], i[1]); });

		return (
			<div className="dateRange_calendarArea_topRow">
				<button
					type="button"
					onClick={() => {
						const newcalendarMonths = [];
						newcalendarMonths[1 - side] = calendarMonths[1 - side];
						newcalendarMonths[side] = new Date(
							calendarMonths[side].toLocaleString("default", { year: "numeric" }),
							calendarMonths[side].getMonth() - 1,
						);
						setCalendarMonths(newcalendarMonths);
					}}
				>
					<i style={{ backgroundImage: "url(/taqa-h2/arrow.png)" }} />
				</button>
				<i>
					{`${calendarMonths[side].toLocaleString((text ? text[6] : "default"), { month: "short" })} `}
					{yearText}
				</i>
				<button
					type="button"
					onClick={() => {
						const newcalendarMonths = [];
						newcalendarMonths[1 - side] = calendarMonths[1 - side];
						newcalendarMonths[side] = new Date(
							calendarMonths[side].toLocaleString("default", { year: "numeric" }),
							calendarMonths[side].getMonth() + 1,
						);
						setCalendarMonths(newcalendarMonths);
					}}
				>
					<i style={{ backgroundImage: "url(/taqa-h2/arrow.png)" }} />
				</button>
			</div>
		);
	};

	const calendarHead = (
		<div className="dateRange_section dateRange_head">
			<div className="dateRange_day">{text ? text[12] : ""}</div>
			<div className="dateRange_day">{text ? text[13] : ""}</div>
			<div className="dateRange_day">{text ? text[14] : ""}</div>
			<div className="dateRange_day">{text ? text[15] : ""}</div>
			<div className="dateRange_day">{text ? text[16] : ""}</div>
			<div className="dateRange_day">{text ? text[17] : ""}</div>
			<div className="dateRange_day">{text ? text[18] : ""}</div>
		</div>
	);

	const output = dateData[0] && dateData[1]
		? `${dateData[0].toLocaleDateString((text ? text[6] : "default"))} - ${dateData[1].toLocaleDateString((text ? text[6] : "default"))}`
		: "";

	return (
		<>
			<i data-open={openPopup} className="userTable_tool_label">{text ? text[8] : ""}</i>
			<input
				placeholder="DD/MM/YY - DD/MM/YY"
				className="userTable_tool_field"
				onClick={() => setOpenPopup("dateRange")}
				onChange={() => setOpenPopup("dateRange")}
				data-open={openPopup}
				value={`${output}`}
			/>
			<input
				className="userTable_popup_bg"
				onClick={() => {
					setOpenPopup("");
					setFilterValue({ timeFrom: dateData[0], timeTo: dateData[1] });
				}}
			/>
			<div className="userTable_popup_dateRange">
				<div className="dateRange_topRow">
					<button
						type="button"
						onClick={() => {
							setDateData([weekOldDay, today]);
							setCalendarMonths([weekOldDay, today]);
						}}
					>
						{text ? text[10] : ""}
					</button>
					<button
						type="button"
						onClick={() => {
							setDateData([monthOldDay, today]);
							setCalendarMonths([monthOldDay, today]);
						}}
					>
						{text ? text[11] : ""}
					</button>
				</div>
				<div className="dateRange_calendarArea">
					{monthPicker(0)}
					{calendarHead}
					{createCalendar(0)}
				</div>
				<div className="dateRange_calendarArea">
					{monthPicker(1)}
					{calendarHead}
					{createCalendar(1)}
				</div>
			</div>
			<button
				className="dateRange_clear"
				type="button"
				onClick={() => {
					setDateData([false, false]);
					setCalendarMonths([monthOldDay, thisMonth]);
				}}
			>
				<i style={{ backgroundImage: "url(/taqa-h2/exit.png)" }} />
			</button>
		</>
	);
};
