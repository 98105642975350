import React from "react";
import "../styles/RegionPicker.css";
import Button from "@mui/material/Button";
import { useFetchRegionPicker } from "../endpoints";

export const RegionPicker = ({ setRegion, language }) => {
	const { regionPicker, localizedText } = useFetchRegionPicker();

	const regionsItems = regionPicker
		.filter((regionOrFalsy) => !(regionOrFalsy?.hidden))
		.sort((a, b) => a.index - b.index)
		.map((region) => (
			<Region
				setRegion={setRegion}
				key={region.shortcode.toString()}
				region={region}
			/>
		));
	return (
		<div className="RegionPicker">
			<h3>{localizedText ? localizedText.text.content[language][0] : ""}</h3>
			<div className="regions">
				{regionsItems}
			</div>
		</div>
	);
};

export const SignInButton = ({ setRegion, region }) => (
	<Button
		className="RegionPicker-item"
		onClick={() => setRegion(region)}
	>
		<div className="RegionPicker-buttonContent">
			<img src={region.thumbnail} alt={region.name} />
			<br />
			{region.shortName}
		</div>
	</Button>
);

export const Region = ({ setRegion, region }) => (
	<SignInButton
		region={region}
		setRegion={setRegion}
	/>
);
