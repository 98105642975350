import React, { useState, useEffect } from "react";
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useIsAuthenticated,
	useMsal,
} from "@azure/msal-react";
import forge from "node-forge";
import { Redirect, useParams } from "react-router-dom";
import {
	Grid, Stepper, Step, StepLabel,
} from "@mui/material";
import {
	useFetchUserDashboard,
	useFetchRegionByShortcode,
	useFetchVersionNumber,
	useFetchGetOrCreateSession,
} from "../../endpoints";
import { UserDashboardSurvey } from "./Survey";
import UnityExperience from "./UnityExperience";
import { loginRequest } from "../../authConfig";
import "../../styles/dashboard/UserDashboard.css";
import ChangeLocalization from "../ChangeLocalization";

const steps = ["Welcome", "Training", "Attestation"];
const stepsAr = ["مرحبا", "تدريب", "تصديق"];

const isArabic = (lang) => lang && lang.toString().toLowerCase() === "ar";

const UserDashboardStepperEn = ({ activeStep }) => (
	<Stepper activeStep={activeStep}>
		{steps.map((label, index) => (
			<Step key={label}>
				<StepLabel>{activeStep === index ? <b>{label}</b> : label}</StepLabel>
			</Step>
		))}
	</Stepper>
);

const UserDashboardStepperAr = ({ activeStep }) => (
	<div dir="rtl">
		<Stepper activeStep={activeStep}>
			{stepsAr.map((label, index) => (
				<Step key={label}>
					<StepLabel>
						<div className="StepLabelAr">
							{activeStep === index ? <b>{label}</b> : label}
						</div>
					</StepLabel>
				</Step>
			))}
		</Stepper>
	</div>
);

const UserDashboardStepper = ({ activeStep, lang }) => {
	if (lang === "AR") {
		return <UserDashboardStepperAr activeStep={activeStep} />;
	}

	return <UserDashboardStepperEn activeStep={activeStep} />;
};

export const UserDashboard = ({ lang, setLanguage }) => {
	const [redirectTo, setRedirectTo] = useState(null);

	const [currentView, setcurrentView] = useState("Welcome");
	const [currentStep, setCurrentStep] = useState(0);
	const { regionShortcode } = useParams();
	const isAuthenticated = useIsAuthenticated();
	const [accessToken, setAccessToken] = useState(null);
	const { instance, accounts } = useMsal();
	const authRegion = useFetchRegionByShortcode(regionShortcode);
	const version = useFetchVersionNumber();
	const [hash, setHash] = useState(false);

	useEffect(() => {
		if (isAuthenticated && authRegion) {
			instance.acquireTokenSilent({
				...loginRequest(authRegion.adId, authRegion.groupId, authRegion.appId),
				account: accounts[0],
			})
				.then((tokenResponse) => setAccessToken(tokenResponse.accessToken));
			const md = forge.md.sha256.create();
			md.update(accounts[0].username + authRegion.salt);
			setHash(md.digest().toHex());
		}
	}, [isAuthenticated, authRegion, accounts, instance]);

	const { sessionId, buildUrl } = useFetchGetOrCreateSession(setRedirectTo, accessToken, regionShortcode, hash);
	const { localizedText: text } = useFetchUserDashboard(setRedirectTo, accessToken);

	let fallbackRender = (<></>);
	if (redirectTo) {
		fallbackRender = (<Redirect to={redirectTo} />);
	}

	return !text || redirectTo ? fallbackRender : (
		<>
			<AuthenticatedTemplate>
				{currentView === "Welcome"
					? (
						<UserDashboardWelcome
							setcurrentView={setcurrentView}
							text={text.UserDashboardWelcome.text.content[lang]}
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
							lang={lang}
							setLanguage={setLanguage}
						/>
					) : <></>}
				{currentView === "Training"
					? (
						<UserDashboardTraining
							setcurrentView={setcurrentView}
							text={text.UserDashboardTraining.text.content[lang]}
							token={accessToken}
							sessionId={sessionId}
							buildUrl={buildUrl}
							version={version}
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
							lang={lang}
							setLanguage={setLanguage}
						/>
					) : <></>}
				{currentView === "Attestation"
					? (
						<UserDashboardAttestation
							setcurrentView={setcurrentView}
							text={text.UserDashboardAttestation.text.content[lang]}
							currentStep={currentStep}
							sessionId={sessionId}
							token={accessToken}
							setCurrentStep={setCurrentStep}
							lang={lang}
							setLanguage={setLanguage}
						/>
					) : <></>}
				{currentView === "Survey"
					? (
						<UserDashboardSurvey
							setcurrentView={setcurrentView}
							text={text.UserDashboardSurvey.text.content[lang]}
							currentStep={currentStep}
							sessionId={sessionId}
							token={accessToken}
							setCurrentStep={setCurrentStep}
							lang={lang}
							setLanguage={setLanguage}
						/>
					) : <></>}
				{currentView === "Complete"
					? (
						<UserDashboardComplete
							setcurrentView={setcurrentView}
							text={text.UserDashboardComplete.text.content[lang]}
							token={accessToken}
							hash={hash}
							regionShortcode={regionShortcode}
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
							lang={lang}
							setLanguage={setLanguage}
						/>
					) : <></>}
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<Redirect to="/" />
			</UnauthenticatedTemplate>
		</>
	);
};

export const UserDashboardWelcome = ({
	setcurrentView, text, currentStep, setCurrentStep, lang, setLanguage,
}) => {
	const [page, turnPage] = useState(0);
	useEffect(() => setCurrentStep(0));
	return (
		<>
			<div className="userDash_topBar">
				<Grid container>
					<Grid item xs={12}>
						<div
							style={{
								flexDirection: isArabic(lang) ? "row-reverse" : "row",
							}}
							className="userDash_topBar_content"
						>
							<span>&nbsp;</span>
							<ChangeLocalization language={lang} setLanguage={setLanguage} />
						</div>
					</Grid>
				</Grid>
			</div>
			<div
				className="userDashboard"
				data-mode="0"
				style={
					isArabic(lang) ? {
						direction: "rtl",
						textAlign: "right",
					} : {
						direction: "ltr",
						textAlign: "left",
					}
				}
			>
				<br />
				<UserDashboardStepper activeStep={currentStep} lang={lang} />
				<br />
				<h3
					className="userDashboard_title"
				>
					{text ? text[12] : <></>}
				</h3>
				<Grid
					container
					className="userDashInner"
				>
					<Grid item xs={4} className="userDashInner_withButtons">
						<div className="userDash_InnerBox" data-visible={page === 0}>
							<p>{text ? text[0] : <>...</>}</p>
							<br />
							<p>{text ? text[1] : <></>}</p>
							<br />
							<p>{text ? text[2] : <></>}</p>
						</div>
						<div className="userDash_InnerBox" data-inner data-visible={page === 1}>
							<h4>{text ? text[3] : <></>}</h4>
							<br />
							<ol>
								<li>{text ? text[4] : <></>}</li>
								<li>{text ? text[5] : <></>}</li>
								<li>{text ? text[6] : <></>}</li>
							</ol>
							<br />
							<p>{text ? text[7] : <></>}</p>
						</div>
						<div className="userDash_InnerBox" data-inner data-visible={page === 2}>
							<h4 className="userDash_nowrap">
								<img width="40" alt="" src="/taqa-h2/audio.png" />
								<span>{text ? text[8] : <></>}</span>
							</h4>
							<hr />
							<p>{text ? text[9] : <></>}</p>
						</div>
						<div className="userDash_InnerBox" data-visible={page === 2}>
							<p>{text ? text[10] : <></>}</p>
						</div>
						<div className="userDash_InnerBox_buttons">
							<button data-picked={page === 0} type="button" onClick={() => turnPage(0)}>1</button>
							<button data-picked={page === 1} type="button" onClick={() => turnPage(1)}>2</button>
							<button data-picked={page === 2} type="button" onClick={() => turnPage(2)}>3</button>
						</div>
					</Grid>
					<Grid item xs={8}>
						<div className="userDash_InnerBox">
							<img alt="user experience" src="/taqa-h2/transition.jpeg" />
						</div>
					</Grid>
					<Grid item xs={12}>
						<hr />
						<br />
						<button
							type="submit"
							onClick={() => setcurrentView("Training")}
						>
							{text ? text[13] : <></>}
							<div
								className="buttonAfter"
								style={
									isArabic(lang) ? {
										transform: "rotate(0deg)",
										margin: "0 0px 0 -12px",
									} : {
										transform: "rotate(180deg)",
										margin: "0 -8px 0 8px",
									}
								}
							/>
						</button>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export const UserDashboardTraining = ({
	setcurrentView,
	text,
	token,
	sessionId,
	buildUrl,
	version,
	currentStep,
	setCurrentStep,
	lang,
	setLanguage,
}) => {
	useEffect(() => setCurrentStep(1));

	return (
		<>
			<div className="userDash_topBar">
				<Grid container>
					<Grid item xs={12}>
						<div
							style={{
								flexDirection: isArabic(lang) ? "row-reverse" : "row",
							}}
							className="userDash_topBar_content"
						>
							<span>
								{
									isArabic(lang) ? (
										<button
											className="userDash_exitLeft"
											type="button"
											onClick={() => setcurrentView("Welcome")}
										>
											{text ? text[0] : <></>}
											<i style={{
												backgroundImage: "url(/taqa-h2/arr.png)",
												transform: "scaleX(-1)",
											}}
											/>
										</button>
									) : (
										<button
											className="userDash_exitLeft"
											type="button"
											onClick={() => setcurrentView("Welcome")}
										>
											<i style={{
												backgroundImage: "url(/taqa-h2/arr.png)",
											}}
											/>
											{text ? text[0] : <></>}
										</button>
									)
								}

							</span>
							<ChangeLocalization language={lang} setLanguage={setLanguage} />
						</div>

					</Grid>
				</Grid>
			</div>
			<div
				className="userDashboard"
				data-mode="0"
				style={
					isArabic(lang) ? {
						direction: "rtl",
						textAlign: "right",
					} : {
						direction: "ltr",
						textAlign: "left",
					}
				}
			>
				<br />
				<UserDashboardStepper activeStep={currentStep} lang={lang} />
				<br />
				<h3 className="userDashboard_title">
					{text ? text[2] : <></>}
				</h3>
				<Grid
					container
					className="userDashInner userDashInner_training"
					align="inherit"
				>
					<Grid item xs={4} align="inherit">
						<div className="userDash_InnerBox">
							<p>{text ? text[3] : <></>}</p>
						</div>
						<hr />
						<br />
						<button
							type="submit"
							onClick={() => setcurrentView("Attestation")}
						>
							{text ? text[4] : <></>}

						</button>
					</Grid>
					<Grid item xs={8} align="inherit">
						<div className="userDash_InnerBox userDash_experience" style={{ backgroundImage: "url(/taqa-h2/transition.jpeg" }}>
							<UnityExperience
								accessToken={token}
								sessionId={sessionId}
								buildUrl={buildUrl}
								language={lang}
							/>
						</div>
						<div className="VersionStyle">
							{`${version || "v0.0.0"}`}
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export const LocCheckboxItem = ({ required, lang, children }) => (
	<>
		<input
			required={required}
			type="checkbox"
			style={{ float: isArabic(lang) ? "right" : "left" }}
		/>
		{children}
	</>
);

export const UserDashboardAttestation = ({
	setcurrentView, text, currentStep, setCurrentStep, sessionId, token, lang, setLanguage,
}) => {
	useEffect(() => setCurrentStep(2));

	const handleSubmit = (e) => {
		e.preventDefault();
		async function fetchData() {
			const object = {};
			const formData = new FormData(e.target);
			formData.forEach((value, key) => {
				object[key] = value;
			});
			await fetch(`/taqa-h2/api/v2/session/${sessionId}/attestation`, {
				method: "PUT",
				headers: { Authorization: `Bearer ${token}` },
			});
			setcurrentView("Survey");
		}
		fetchData();
	};

	return (
		<>
			<div
				className="userDash_topBar"
			>
				<Grid container>
					<Grid item xs={12}>
						<div
							style={{
								flexDirection: isArabic(lang) ? "row-reverse" : "row",
							}}
							className="userDash_topBar_content"
						>
							<span>
								{
									isArabic(lang) ? (
										<button
											className="userDash_exitLeft"
											type="button"
											onClick={() => setcurrentView("Training")}
										>
											{text ? text[18] : <></>}
											<i style={{
												backgroundImage: "url(/taqa-h2/arr.png)",
												transform: "scaleX(-1)",
											}}
											/>
										</button>
									) : (
										<button
											className="userDash_exitLeft"
											type="button"
											onClick={() => setcurrentView("Training")}
										>
											<i style={{ backgroundImage: "url(/taqa-h2/arr.png)" }} />
											{text ? text[18] : <></>}
										</button>
									)
								}

							</span>
							<ChangeLocalization language={lang} setLanguage={setLanguage} />

						</div>
					</Grid>
				</Grid>
			</div>
			<div
				className="userDashboard"
				data-mode="0"
				style={
					isArabic(lang) ? {
						direction: "rtl",
						textAlign: "right",
					} : {
						direction: "ltr",
						textAlign: "left",
					}
				}
			>
				<br />
				<UserDashboardStepper activeStep={currentStep} lang={lang} />
				<br />
				<h3 className="userDashboard_title userDash_wide">
					{text ? text[19] : <></>}
				</h3>
				<Grid
					container
					className="userDashInner"
				>
					<Grid item xs={12}>
						<form onSubmit={handleSubmit}>
							<div className="userDash_InnerBox">
								<p>{text ? text[0] : <></>}</p>

							</div>
							<div className="userDash_InnerBox_white">
								<LocCheckboxItem required="required" lang={lang}>
									<p>
										{text ? text[1] : <></>}
										<br />
										{text ? text[2] : <></>}
									</p>
									<div className="userDash_InnerBox_indent">
										<ul>
											<li>{text ? text[3] : <></>}</li>
											<li>{text ? text[4] : <></>}</li>
											<li>{text ? text[5] : <></>}</li>
											<li>{text ? text[6] : <></>}</li>
											<li>{text ? text[7] : <></>}</li>
											<li>{text ? text[8] : <></>}</li>
											<li>{text ? text[9] : <></>}</li>
											<li>{text ? text[10] : <></>}</li>
											<li>{text ? text[11] : <></>}</li>
											<li>{text ? text[12] : <></>}</li>
										</ul>
										<div className="userDash_InnerBox">
											<p>{text ? text[13] : <></>}</p>
										</div>
									</div>
									<br />
								</LocCheckboxItem>
								<LocCheckboxItem required="required" lang={lang}>
									<p>{text ? text[14] : <></>}</p>
								</LocCheckboxItem>
								<LocCheckboxItem required="required" lang={lang}>
									<p>{text ? text[15] : <></>}</p>
								</LocCheckboxItem>
								<LocCheckboxItem required="required" lang={lang}>
									<p>{text ? text[16] : <></>}</p>
								</LocCheckboxItem>
							</div>
							<hr />
							<br />
							<button type="submit">
								{text ? text[17] : <></>}
							</button>
						</form>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export const UserDashboardComplete = ({
	setcurrentView,
	text,
	setCurrentStep,
	lang,
	setLanguage,
}) => {
	useEffect(() => setCurrentStep(2));

	return (
		<>
			<div className="userDash_topBar">
				<Grid container>
					<Grid item xs={12}>
						<div
							style={{
								flexDirection: isArabic(lang) ? "row-reverse" : "row",
							}}
							className="userDash_topBar_content"
						>
							<span>
								{
									isArabic(lang) ? (
										<button
											className="userDash_exitLeft"
											type="button"
											onClick={() => setcurrentView("Welcome")}
										>
											{text ? text[0] : <></>}
											<i style={{ backgroundImage: "url(/taqa-h2/arr.png)", transform: "scaleX(-1)" }} />
										</button>
									) : (
										<button
											className="userDash_exitLeft"
											type="button"
											onClick={() => setcurrentView("Welcome")}
										>
											<i style={{ backgroundImage: "url(/taqa-h2/arr.png)" }} />
											{text ? text[0] : <></>}
										</button>
									)
								}
							</span>
							<ChangeLocalization language={lang} setLanguage={setLanguage} />
						</div>

					</Grid>
				</Grid>
			</div>
			<div
				className="userDashboard"
				data-mode="0"
				style={
					isArabic(lang) ? {
						direction: "rtl",
						textAlign: "right",
					} : {
						direction: "ltr",
						textAlign: "left",
					}
				}
			>
				<h3 className="userDashboard_title userDash_wide">
					{text ? text[1] : <></>}
				</h3>
				<Grid container className="userDashInner">
					<Grid item xs={12}>
						<div className="userDash_InnerBox">
							<p>{text ? text[2] : <></>}</p>
							<hr />
							<br />
							<button onClick={() => setcurrentView("Welcome")} type="submit">{text ? text[3] : <></>}</button>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};
