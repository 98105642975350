import React, { useEffect, useState } from "react";
import { getNormalizedBaseUrlFromDocumentURL } from "../../authConfig";
import "../../styles/dashboard/UnityExperience.css";

export default function UnityExperience({
	accessToken,
	sessionId,
	buildUrl,
	language,
}) {
	const [gameLoading, setGameLoading] = useState(false);
	const [gameLoaded, setGameLoaded] = useState(false);

	const addBuildFrameworkScript = () => {
		const buildFrameworkScript = document.createElement("script");
		buildFrameworkScript.async = true;
		buildFrameworkScript.src = `${buildUrl}Build/WebGL.framework.js.gz`;
		document.body.appendChild(buildFrameworkScript);
	};

	const addLoadUnityScript = () => {
		const loadUnityScriptText = `window.unityInstance=createUnityInstance(document.querySelector("#unity-canvas"),{
			dataUrl: "${buildUrl}Build/WebGL.data.gz",
			frameworkUrl: "${buildUrl}Build/WebGL.framework.js.gz",
			codeUrl: "${buildUrl}Build/WebGL.wasm.gz",
			streamingAssetsUrl: "StreamingAssets",
			companyName: "TAQA",
			productName: "Ethic & Complience Training Module",
			productVersion: "1.0",
			// matchWebGLToCanvasSize: false, // Uncomment this to separately control
			// WebGL canvas render size and DOM element size.
			// devicePixelRatio: 1, // Uncomment this to override low DPI rendering on high DPI displays.
		})`;
		const loadUnityScript = document.createElement("script");
		loadUnityScript.text = loadUnityScriptText;
		loadUnityScript.onload = () => {
			addBuildFrameworkScript();
		};
		document.body.appendChild(loadUnityScript);
		window.unityInstance
			.then((instance) => {
				instance.SendMessage(
					"WebMessageReceiver",
					"ReceiveHostName",
					`${getNormalizedBaseUrlFromDocumentURL(window.location.href)}`,
				);
				instance.SendMessage("WebMessageReceiver", "ReceiveToken", accessToken);
				instance.SendMessage("WebMessageReceiver", "ReceiveSessionId", sessionId);
				setTimeout(() => {
					setGameLoaded(true);
				}, 10000);
			})
			.catch((err) => {
				// eslint-disable-next-line
				console.log(err);
			});
	};

	const startExperience = () => {
		if (buildUrl) {
			setGameLoading(true);
			const buildLoaderScript = document.createElement("script");
			buildLoaderScript.src = buildUrl && buildUrl !== "none" ? `${buildUrl}Build/WebGL.loader.js` : "";
			buildLoaderScript.onload = addLoadUnityScript;
			document.body.appendChild(buildLoaderScript);
		}
	};

	useEffect(() => {
		if (window.unityInstance) {
			window.unityInstance
				.then((instance) => {
					instance.SendMessage("App", "ReceiveToken", accessToken);
				});
		}
	}, [
		/*
		window.unityInstance,
		  Outer scope values like 'window.unityInstance' aren't valid dependencies
		  because mutating them doesn't re-render the component
		*/
		accessToken]);

	const handleFullScreen = () => {
		const canvas = document.getElementById("unity-canvas");
		if (canvas.webkitRequestFullScreen) {
			canvas.webkitRequestFullScreen();
		} else {
			canvas.mozRequestFullScreen();
		}
	};

	return (
		<>
			<canvas onLoad={() => setGameLoaded(true)} data-start={gameLoading} id="unity-canvas" />
			<button
				className="startExperience"
				type="button"
				onClick={startExperience}
			>
				{language && language.toString().toLowerCase() === "ar" ? "بدأ" : "Start"}
			</button>
			{
				gameLoaded ? (
					<button className="fullScreen" type="button" onClick={handleFullScreen}>
						<svg width="20" height="20" viewBox="0 0 20 20">
							<path fillRule="evenodd" d="M1 1v6h2V3h4V1H1zm2 12H1v6h6v-2H3v-4zm14 4h-4v2h6v-6h-2v4zm0-16h-4v2h4v4h2V1h-2z" />
						</svg>
					</button>
				) : <></>
			}

		</>
	);
}
