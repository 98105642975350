import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import "../../styles/admin/ScoreTotals.css";

export const ScoreTotals = ({
	text,
	lang,
	integers,
	regionStats,
}) => {
	const regionInfo = regionStats || {
		title: {
			en: "",
			ar: "",
		},
		numberOfCompletions: 0,
		numberOfSessions: 0,
		averageScore: 0,
		maximumScore: 0,
		averageDuration: 0,
		longestDuration: 0,
	};
	return (
		<div className="scoreTotals">
			<Grid container className="scoreTotals_inner">
				<Grid className="scoreTotals_heading" item xs={12}>
					<h3>{!regionInfo.title[lang]}</h3>
				</Grid>
				<Grid container className="scoreTotals_totals" item xs={12}>
					<ScoreTotal
						integers={integers}
						lang={lang}
						className="scoreTotals_icon scoreTotals_iconusers"
						icon="url(/taqa-h2/check.png)"
						value={(regionInfo.numberOfCompletions / regionInfo.numberOfSessions) * 100}
						heading={`${regionInfo.numberOfCompletions} / ${regionInfo.numberOfSessions} ${text ? text[2] : ""}`}
						subheading={text ? text[1] : ""}
					/>
					<ScoreTotal
						integers={integers}
						lang={lang}
						className="scoreTotals_icon scoreTotals_iconscore"
						icon="url(/taqa-h2/star.png)"
						value={(regionInfo.averageScore / regionInfo.maximumScore) * 100}
						heading={`${regionInfo.averageScore} / ${regionInfo.maximumScore}`}
						subheading={text ? text[5] : ""}
					/>
					<ScoreTotal
						integers={integers}
						lang={lang}
						className="scoreTotals_icon scoreTotals_icontime"
						icon="url(/taqa-h2/time.png)"
						value={(regionInfo.averageDuration / regionInfo.longestDuration) * 100}
						heading={`${Math.floor(regionInfo.averageDuration / 60)} 
						: ${regionInfo.averageDuration - Math.floor(regionInfo.averageDuration / 60) * 60}`}
						subheading={text ? text[4] : ""}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export const ScoreTotal = ({
	className,
	value,
	heading,
	subheading,
	icon,
	lang,
	integers,
}) => {
	let headingedited = heading;
	if (lang && integers) integers[lang].forEach((i) => { headingedited = headingedited.replaceAll(i[0], i[1]); });
	let subheadingedited = subheading;
	if (lang && integers) {
		integers[lang].forEach((i) => {
			subheadingedited = subheadingedited.replaceAll(i[0], i[1]);
		});
	}

	return (
		<Grid className="scoreTotals_total" item xs={4}>
			<CircularProgress
				className="scoreTotals_staticCircle"
				size={64}
				thickness={4}
				variant="determinate"
				value={100}
			/>
			<CircularProgress
				className="scoreTotals_valueCircle"
				size={64}
				thickness={4}
				variant="determinate"
				value={value}
			/>
			<div className="scoreTotals_icon_box">
				<div className={className} style={{ backgroundImage: icon }} />
			</div>
			<div className="scoreTotals_text">
				<h4>{headingedited}</h4>
				<span>{subheadingedited}</span>
			</div>
		</Grid>
	);
};
