import React, { useState } from "react";
import {
	Switch,
	Route,
	Redirect,
	NavLink,
} from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { SignIn } from "./components/SignIn";
import { PageLayout } from "./components/PageLayout";
import { AdminDashboard } from "./components/admin/AdminDashboard";
import { UserDashboard } from "./components/dashboard/UserDashboard";
import { NoAccessDashboard } from "./components/dashboard/NoAccessDashboard";
import { SignOutButton } from "./components/SignOutButton";
import { useFetchSettings } from "./endpoints";
import "./styles/App.css";

export const App = () => {
	const { accounts } = useMsal();

	const languages = useFetchSettings("languages");
	const [language, setLanguage] = useState(false);
	if (!language && languages) setLanguage(languages[0]);

	const account = accounts?.length ? accounts[0] : null;
	const username = account ? account.name : <></>;
	const navitems = [
		(accounts?.length ? <SignOutButton key={1} /> : <div key={0} />),
		(<NavLink to="/taqa-h2/participant/" key={2}>View as Participant</NavLink>),
		(<NavLink to="/taqa-h2/admin/" key={3}>View as Admin</NavLink>),
	];

	return (
		<div className="App">
			<PageLayout
				username={username}
				navitems={navitems}
				langs={languages}
				setLanguage={setLanguage}
				language={language}
			>
				<Switch>
					{/* MUST BE FIRST */}
					<Route exact path="/taqa-h2/:desiredRedirect/">
						<SignIn language="EN" />
					</Route>
					<Route exact path="/taqa-h2/:regionShortcode/participant">
						<UserDashboard setLanguage={setLanguage} lang={language} />
					</Route>
					<Route exact path="/taqa-h2/:regionShortcode/admin">
						<AdminDashboard lang="EN" />
					</Route>
					<Route exact path="/taqa-h2/:regionShortcode/no-access">
						<NoAccessDashboard lang="EN" />
					</Route>
					<Route exact path="/taqa-h2/">
						<SignIn language="EN" />
					</Route>
					<Redirect to="/taqa-h2/" />
				</Switch>
			</PageLayout>
		</div>
	);
};
