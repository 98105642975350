import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import {
	fetchMergedUsersFromAPI,
	fetchMergedSurveysFromAPI,
	fillInPageCacheOrSetUsersEmpty,
	PageCacheContext,
	setUsersForCachePage,
} from "../../endpoints";

export default function DownloadSurveys({
	text,
	users,
	fetchingData,
	filterValue,
}) {
	const pageCache = useContext(PageCacheContext);
	const [processing, setProcessing] = useState(false);
	const [file, setFileLink] = useState(null);
	useEffect(() => {
		setFileLink(null);
	}, [pageCache.accessToken, pageCache.regionInfo, filterValue]);

	const handleClick = async () => {
		if (pageCache?.buffer?.length) {
			// Start processing!
			setProcessing(true);
			// Sweep all the way to the last user page
			let allPages = [];
			await fillInPageCacheOrSetUsersEmpty(
				pageCache.buffer,
				(pages) => { allPages = pages; },
				pageCache.buffer.length - 1,
				pageCache.accessToken,
				() => { /* noop */ },
			);
			// Reduce all cached azure AD users to merged user with session arrays
			const mergedSurveys = !Array.isArray(allPages) ? await processUserPage(allPages) : await allPages.reduce(
				async (accumulatorP, currentValue) => {
					let accumulatorValue = await accumulatorP;
					if (!Array.isArray(accumulatorValue)) {
						accumulatorValue = await processUserPage(accumulatorValue);
					}
					if (!currentValue) {
						return accumulatorValue;
					}
					let sortedUsersMap = [];
					setUsersForCachePage((users) => { sortedUsersMap = users; }, currentValue, pageCache.regionInfo);
					const mergedUsers = await fetchMergedUsersFromAPI(
						sortedUsersMap,
						pageCache.accessToken,
						filterValue.timeFrom,
						filterValue.timeFrom,
						() => { /* noop */ },
					);
					const mergedSurveys = await fetchMergedSurveysFromAPI(mergedUsers, pageCache.accessToken);
					return accumulatorValue.concat(mergedSurveys);
				},
			);
			setCsvFileFromMergedSurveys(mergedSurveys, setFileLink);
			pageCache.setBuffer(allPages);
			// Done processing!
			setProcessing(false);
		}
	};

	const doneWithFile = () => { setFileLink(null); };
	const buttonText = text && file ? text[28] : "COMPILE SURVEYS";
	if (file) {
		return (
			<a href={file} download="surveyData.csv" onClick={doneWithFile}>
				<button
					disabled={fetchingData || processing}
					type="button"
					style={{ "min-width": 200 }}
				>
					{buttonText}
				</button>
			</a>
		);
	}
	return (
		<button
			disabled={fetchingData || processing}
			onClick={handleClick}
			type="button"
			data-count={users.length}
			style={{ "min-width": 200 }}
		>
			{
				!processing ? <img src="/taqa-h2/csvIcon.png" alt="csv icon" /> : <></>
			}
			&nbsp;
			{
				processing
					? <CircularProgress size={18} style={{ color: "white" }} />
					: buttonText
			}
		</button>
	);

	async function processUserPage(userPage) {
		let firstPagesortedUsersMap = [];
		setUsersForCachePage(
			(users) => { firstPagesortedUsersMap = users; },
			userPage,
			pageCache.regionInfo,
		);
		const mergedUsers = await fetchMergedUsersFromAPI(
			firstPagesortedUsersMap,
			pageCache.accessToken,
			filterValue.timeFrom,
			filterValue.timeFrom,
			() => { },
		);
		return fetchMergedSurveysFromAPI(mergedUsers, pageCache.accessToken);
	}
}
function setCsvFileFromMergedSurveys(mergedSurveys, setFileLink) {
	const sampleSurvey = {
		hash: "",
		sessionid: "",
		body: {
			Question1: "",
			Question2: "",
			Question3: "",
			Question4: "",
			Question5: "",
			Question6: "",
			Question7: "",
			Question8: "",
			Question9: "",
			Question10: "",
			Question11: "",
			Question12: "",
			Question13: "",
			Question14: "",
			Question15: "",
			Question16a: "",
			Question16b: "",
			Question17: "",
		},
		id: "",
	};
	const fieldNames = Object.keys(sampleSurvey.body);
	// eslint-disable-next-line quotes
	const firstRow = [`"hash", "sessionId", "surveyId", "${fieldNames.join(`","`)}"\n`];
	const dataRows = mergedSurveys.map(
		(survey) => `${[
			// eslint-disable-next-line quotes
			`"${survey.hash}"`, `"${survey.sessionid}"`, `"${survey.id}"`,
		].concat(
			fieldNames.map((fieldName) => {
				if (fieldName in survey.body) {
					const value = survey.body[fieldName]?.toString() || "";
					// eslint-disable-next-line no-control-regex, quotes
					const sanitizedValue = value.replace(/[\u0000-\u001F\u007F-\u009F]/g, " ").replace(/"/g, `'`);
					return `"${sanitizedValue}"`;
				}
				// eslint-disable-next-line quotes
				return `""`;
			}),
		).join(",")}\n`,
	).flat();
	const data = new Blob(firstRow.concat(dataRows), { type: "text/plain" });

	const file = window.URL.createObjectURL(data);
	setFileLink(file);
}
