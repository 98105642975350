import React from "react";
import { useMsal } from "@azure/msal-react";
import {
	clearAuthRegionStorage,
} from "../authConfig";

export const SignOutButton = () => {
	const { instance, accounts } = useMsal();

	const handleLogout = (/* logoutType */) => {
		clearAuthRegionStorage();
		instance.logoutRedirect({
			account: accounts?.at(0),
			postLogoutRedirectUri: "/taqa-h2/",
			mainWindowRedirectUri: "/taqa-h2/",
		});
	};
	return (
		<button
			type="button"
			className="signout-button"
			onClick={() => handleLogout("redirect")}
		>
			Sign Out
		</button>
	);
};
