import React, { useContext, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import {
	fetchMergedUsersFromAPI,
	fillInPageCacheOrSetUsersEmpty,
	PageCacheContext,
	setUsersForCachePage,
} from "../../endpoints";

export default function Download({
	text,
	users,
	fetchingData,
	filterValue,
}) {
	const pageCache = useContext(PageCacheContext);
	const [processing, setProcessing] = useState(false);
	const [file, setFileLink] = useState(null);
	useEffect(() => {
		setFileLink(null);
	}, [pageCache.accessToken, pageCache.regionInfo, filterValue]);

	const handleClick = async () => {
		if (pageCache?.buffer?.length) {
			// Start processing!
			setProcessing(true);
			// Sweep all the way to the last user page
			let allPages = [];
			await fillInPageCacheOrSetUsersEmpty(
				pageCache.buffer,
				(pages) => { allPages = pages; },
				pageCache.buffer.length - 1,
				pageCache.accessToken,
				() => { /* noop */ },
			);
			// Reduce all cached azure AD users to merged user with session arrays
			const mergedUsers = !Array.isArray(allPages) ? await processUserPage(allPages) : await allPages.reduce(
				async (accumulatorP, currentValue) => {
					let accumulatorValue = await accumulatorP;
					if (!Array.isArray(accumulatorValue)) {
						accumulatorValue = await processUserPage(accumulatorValue);
					}
					if (!currentValue) {
						return accumulatorValue;
					}
					let sortedUsersMap = [];
					setUsersForCachePage((users) => { sortedUsersMap = users; }, currentValue, pageCache.regionInfo);
					const newValue = await fetchMergedUsersFromAPI(
						sortedUsersMap,
						pageCache.accessToken,
						filterValue.timeFrom,
						filterValue.timeFrom,
						() => { /* noop */ },
					);
					return accumulatorValue.concat(newValue);
				},
			);
			setCsvFileFromMergedUsers(mergedUsers, setFileLink);
			pageCache.setBuffer(allPages);
			// Done processing!
			setProcessing(false);
		}
	};

	const doneWithFile = () => { setFileLink(null); };
	const buttonText = text ? text[file ? 28 : 25] : "";
	if (file) {
		return (
			<a href={file} download="data.csv" onClick={doneWithFile}>
				<button
					disabled={fetchingData || processing}
					type="button"
					style={{ "min-width": 200 }}
				>
					{buttonText}
				</button>
			</a>
		);
	}
	return (
		<button
			disabled={fetchingData || processing}
			onClick={handleClick}
			type="button"
			data-count={users.length}
			style={{ "min-width": 200 }}
		>
			{
				!processing ? <img src="/taqa-h2/csvIcon.png" alt="csv icon" /> : <></>
			}
			&nbsp;
			{
				processing
					? <CircularProgress size={18} style={{ color: "white" }} />
					: buttonText
			}
		</button>
	);

	async function processUserPage(userPage) {
		let firstPagesortedUsersMap = [];
		setUsersForCachePage(
			(users) => { firstPagesortedUsersMap = users; },
			userPage,
			pageCache.regionInfo,
		);
		return fetchMergedUsersFromAPI(
			firstPagesortedUsersMap,
			pageCache.accessToken,
			filterValue.timeFrom,
			filterValue.timeFrom,
			() => { },
		);
	}
}
function setCsvFileFromMergedUsers(users, setFileLink) {
	const firstSession = users?.find((p) => p.sessions?.length > 0)?.sessions[0];
	if (firstSession) {
		const fieldNames = Object.keys(firstSession).map((k) => (k.toLowerCase() === "progress" ? "scenarioProgress" : k));
		const firstRow = [`name, email, department, mail, employeeId, progress, ${fieldNames.join(",")}\n`];
		const dataRows = users.map(
			(person) => person.sessions.map(
				(session) => `${[
					person.userData.displayName,
					person.userData.userPrincipalName,
					person.userData.department?.replace(/,/g, " ") || "",
					person.userData.mail || "",
					person.userData.employeeId || "",
					(session.progress * 0.90) + (session.survey * 0.05) + (session.attestation * 0.05),
				].concat(
					fieldNames.map(
						(x) => {
							const fieldName = (x === "scenarioProgress") ? "progress" : x;
							const value = (fieldName in session) ? session[fieldName] : "";
							// eslint-disable-next-line no-control-regex
							const sanitizedValue = (value instanceof String) ? value.replace(/[\u0000-\u001F\u007F-\u009F]/g, " ").replace(/,/g, " ") : value;
							return sanitizedValue;
						},
					),
				).join(",")}\n`,
			),
		).flat();
		const data = new Blob(firstRow.concat(dataRows), { type: "text/plain" });

		const file = window.URL.createObjectURL(data);
		setFileLink(file);
	}
}
