import React, { useState, useEffect } from "react";
import forge from "node-forge";
import SortedArrayMap from "collections/sorted-array-map";
import { callMsGraph } from "./graph";
import { graphConfig } from "./authConfig";
import { GraphUserModel } from "./components/admin/GraphUserModel";

// eslint-disable-next-line no-control-regex, max-len
const externalUserUpnRegex = /^(?<username>[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")_(?<domain>(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])#EXT#.+$/i;

const initialPageCacheContextValue = {
	buffer: [],
	setBuffer: undefined,
	regionInfo: undefined,
	accessToken: undefined,
};

export const reverseAssignFromModel = (ModelProto, dataObject) => {
	const model = new ModelProto();
	Object.keys(model).forEach((key) => {
		Object.assign(model, { [key]: dataObject[key] });
	});
	return model;
};

export const PageCacheContext = React.createContext(initialPageCacheContextValue);

export const useFetchUsers = (regionInfo, searchValue, pageSize, currentPage, accessToken, selectedRegionID) => {
	const [users, setUsers] = useState([]);
	const [firstPageResponse, setFirstPageResponse] = useState(null);
	const [pageCache, setPageCache] = useState([]);

	useEffect(() => {
		if (!regionInfo || regionInfo.id !== selectedRegionID) {
			setFirstPageResponse(null);
			return;
		}
		const fetchFirstValidPage = async (accessTok, queryStr) => {
			const response = await callMsGraph(accessTok, queryStr);
			if (response?.error || !("@odata.count" in response) || !("value" in response)) {
				setFirstPageResponse(null);
				return;
			}
			setFirstPageResponse(response);
		};

		if (!accessToken || !pageSize) {
			setFirstPageResponse(null);
			return;
		}
		const queryString = graphConfig.graphGroupUsersSearchEndpoint(
			regionInfo.groupId,
			searchValue,
			pageSize,
		);
		fetchFirstValidPage(accessToken, queryString);
	}, [currentPage, regionInfo, searchValue, pageSize, accessToken, selectedRegionID]);

	useEffect(() => {
		if (!firstPageResponse) {
			setPageCache([]);
			return;
		}
		const pageCount = Math.ceil(firstPageResponse["@odata.count"] / Math.max(pageSize, 1));
		setPageCache(Array(pageCount).fill(null).fill(firstPageResponse, 0, Math.min(1, pageCount)));
	}, [firstPageResponse, pageSize]);

	usePageCachePagination(setPageCache, setUsers, pageCache, currentPage, accessToken, regionInfo);
	return [users, pageCache, setPageCache];
};

export const useFetchAdminDashboard = (setRedirectTo, authRegion, selectedRegionId, accessToken) => {
	const [data, setData] = useState({
		adminDashboard: [],
		mappaths: [],
		regiondata: [],
		localizedText: false,
		regionStats: [],
		switchRegion: { regionStats: [], questionStats: [] },
	});
	const [fetchingAdminDashboardData, setFetchingAdminDashboardData] = useState(true);

	useEffect(() => {
		const regionid = selectedRegionId || authRegion?.id;
		const userDataFetch = async (rid, atoken) => {
			const response = await fetch(`/taqa-h2/api/v2/admindashboard/${rid}`, {
				method: "GET",
				headers: { Accept: "application/json", Authorization: `Bearer ${atoken}` },
			});
			setFetchingAdminDashboardData(false);
			if (response.status !== 200) {
				setRedirectTo("/taqa-h2/no-access");
				return;
			}
			try {
				const jsonResponse = await response.json();
				setData(jsonResponse);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log(error);
			}
		};
		if (regionid && accessToken) {
			userDataFetch(regionid, accessToken);
		}
	}, [accessToken, authRegion, selectedRegionId, setRedirectTo]);
	return { data, fetchingAdminDashboardData };
};

export const useFetchUserDashboard = (setRedirectTo, accessToken) => {
	const [data, setData] = useState({ localizedText: false });
	useEffect(() => {
		const dataFetch = async () => {
			const response = await fetch("/taqa-h2/api/v2/userdashboard/", {
				headers: { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` },
			});
			if (response.status !== 200) {
				setRedirectTo("/taqa-h2/no-access");
				return;
			}
			const jsonResponse = await response.json();
			setData(jsonResponse);
		};
		if (accessToken) {
			dataFetch();
		}
	}, [accessToken, setRedirectTo]);
	return data;
};

export const useFetchGetOrCreateSession = (setRedirectTo, accessToken, regionShortcode, hash) => {
	const [data, setData] = useState({ sessionId: false, buildUrl: false });
	useEffect(() => {
		const dataFetch = async () => {
			const response = await fetch(`/taqa-h2/api/v2/region/${regionShortcode}/users/${hash}/session`, {
				method: "POST",
				headers: { Authorization: `Bearer ${accessToken}` },
			});
			if (response.status !== 200) setRedirectTo("/taqa-h2/no-access");
			const jsonResponse = await response.json();
			setData(jsonResponse);
		};
		if (accessToken && hash) {
			dataFetch();
		}
	}, [accessToken, hash, regionShortcode, setRedirectTo]);
	return data;
};

export const useFetchFilteredUsers = (setRedirectTo, { timeFrom, timeTo }, azureUsers, accessToken) => {
	const [filteredUsers, setData] = useState(azureUsers);
	const [fetchingData, setFetchingData] = useState(false);
	useEffect(() => {
		const userDataFetch = async () => {
			const mergedUsers = await fetchMergedUsersFromAPI(azureUsers, accessToken, timeFrom, timeTo, setRedirectTo);
			setData(mergedUsers);
			setFetchingData(false);
		};
		if (accessToken && azureUsers && azureUsers.length) {
			userDataFetch();
			setFetchingData(true);
		} else {
			setData(azureUsers);
		}
	}, [azureUsers, timeFrom, timeTo, accessToken, setRedirectTo]);
	return { filteredUsers, fetchingData };
};

export const useFetchSettings = (field) => {
	const [value, setValue] = useState(false);
	useEffect(() => {
		fetch(`/taqa-h2/api/settings/${field}`)
			.then((response) => response.json()).then((data) => {
				setValue(data.value);
			});
	}, [field]);
	return value;
};

export const useFetchRegionPicker = () => {
	const [regionList, setRegionList] = useState({ regionPicker: [], localizedText: false });
	useEffect(() => {
		fetch("/taqa-h2/api/v2/regionpicker")
			.then((response) => response.json()).then((data) => {
				if (Array.isArray(data.regionPicker)) {
					setRegionList(data);
				}
			});
	}, []);
	return regionList;
};

export const useFetchRegionByShortcode = (shortcode) => {
	const [region, setRegion] = useState(null);
	useEffect(() => {
		if (shortcode) {
			fetch(`/taqa-h2/api/v2/regionByShortcode/${shortcode.toString().toLowerCase()}`)
				.then((response) => response.json()).then((data) => {
					setRegion(data.regionByShortcode);
				});
		}
	}, [shortcode]);
	return region;
};

export const useFetchVersionNumber = () => {
	const [version, setVersion] = useState("");

	useEffect(() => {
		fetch("/taqa-h2/api/experience/path")
			.then((response) => response.json())
			.then((data) => {
				if (data?.version) {
					setVersion(data.version);
				}
			});
	}, []);

	return version;
};

export async function fetchMergedUsersFromAPI(azureUsers, accessToken, timeFrom, timeTo, setRedirectTo) {
	const hashIds = Array.from(azureUsers.keys());
	const response = await fetch("/taqa-h2/api/v2/users/", {
		method: "POST",
		headers: { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` },
		body: JSON.stringify({ hashIds, timeFrom, timeTo }),
	});
	if (response.status !== 200) {
		setRedirectTo("/taqa-h2/no-access");
	}
	const jsonResponse = await response.json();
	const mergedUsers = hashIds.map((hash) => {
		const graphUser = reverseAssignFromModel(GraphUserModel, azureUsers.get(hash));
		const sessions = jsonResponse.users.filter((a) => a.hash === hash);
		if (!sessions.length) {
			sessions[0] = {
				hash: "unknown",
				date: "2022-06-01T00:00:00.005Z",
				progress: 0,
				duration: 0,
				score: 0,
				survey: 0,
				attestation: 0,
				id: "",
			};
		}
		return {
			userData: Object.assign(graphUser, { hash }),
			sessions,
		};
	});
	return mergedUsers;
}

export async function fetchMergedSurveysFromAPI(mergedUsers, accessToken) {
	const sessionIds = mergedUsers.flatMap((user) => user.sessions.map((s) => s.id)).filter((sid) => sid);
	const response = await fetch("/taqa-h2/api/v2/surveys/", {
		method: "POST",
		headers: { "Accept": "application/json", "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` },
		body: JSON.stringify({ sessionIds }),
	});
	if (response.status !== 200) {
		return [];
	}
	const jsonResponse = await response.json();
	const mergedSurveys = mergedUsers.flatMap((user) => {
		const matchingSurveys = user.sessions.flatMap((session) => (
			jsonResponse.surveys.filter((survey) => survey.sessionid === session.id)
		));
		return matchingSurveys.map((survey) => ({
			hash: user.userData.hash,
			...survey,
		}));
	});
	return mergedSurveys;
}

export async function fillInPageCacheOrSetUsersEmpty(pageCache, setPageCache, curPage, accessTok, setUsers) {
	try {
		if (!accessTok) {
			return;
		}

		setPageCache(
			await pageCache.reduce(
				async (accumulatorP, currentValue, index) => {
					const accumulatorValue = await accumulatorP;
					const accumulator = Array.isArray(accumulatorValue)
						? accumulatorValue
						: [accumulatorValue];
					return accumulator.concat((!currentValue && index <= curPage)
						? await callMsGraph(accessTok, accumulator.at(-1)["@odata.nextLink"])
						: currentValue);
				},
			),
		);
	} catch (error) {
		setUsers([]);
	}
}

export function setUsersForCachePage(setUsers, cachedPage, regionInfo) {
	setUsers(
		new SortedArrayMap(cachedPage.value.map((person) => {
			const externalUsernameGroups = person?.userPrincipalName?.match(externalUserUpnRegex)?.groups;
			const md = forge.md.sha256.create();
			md.update((
				externalUsernameGroups
					? `${externalUsernameGroups.username}@${externalUsernameGroups.domain}`
					: person.userPrincipalName
			) + regionInfo?.salt || "");
			return [md.digest().toHex(), person];
		})),
	);
}

function usePageCachePagination(setPageCache, setUsers, pageCache, currentPage, accessToken, regionInfo) {
	useEffect(() => {
		if (pageCache.length > Math.max(0, currentPage)) {
			const cachedCurrentPageResponse = pageCache[currentPage];
			if (!cachedCurrentPageResponse) {
				fillInPageCacheOrSetUsersEmpty(pageCache, setPageCache, currentPage, accessToken, setUsers);
			} else {
				setUsersForCachePage(setUsers, cachedCurrentPageResponse, regionInfo);
			}
		} else {
			setUsers([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageCache, currentPage]);
}
