import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../styles/admin/MapContainer.css";

export const MapContainer = ({
	setSelectedRegionID,
	selectedRegionID,
	regiondata,
}) => {
	const [selectedRegion, setSelectedRegion] = useState(null);

	useEffect(() => {
		if (regiondata?.length > 0 && selectedRegionID) {
			const region = regiondata.find((x) => x.id === selectedRegionID);
			if (region) {
				setSelectedRegion(region);
			}
		}
	}, [regiondata, selectedRegionID]);

	return selectedRegion
		? (
			<Autocomplete
				value={selectedRegion}
				onChange={(event, newValue) => {
					setSelectedRegionID(newValue.id);
				}}
				id="opco-select"
				options={regiondata
					.filter((regionOrFalsy) => !(regionOrFalsy?.hidden))
					.sort((a, b) => a.index - b.index)}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				autoHighlight
				getOptionLabel={(option) => option.name}
				renderOption={(props, option) => (
					// eslint-disable-next-line
					<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
						<img
							loading="lazy"
							width="20"
							src={option.thumbnail}
							alt={option.name}
						/>
						{/* eslint-disable-next-line */}
						{option.shortName}
					</Box>
				)}
				renderInput={(params) => (
					<TextField
						// eslint-disable-next-line
						{...params}
						label="Choose a user group"
						inputProps={{
							...params.inputProps,
							autoComplete: "off", // disable autocomplete and autofill
						}}
					/>
				)}
			/>
		)
		: (
			<></>
		);
};
