import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Redirect, useParams } from "react-router-dom";
import { RegionPicker } from "./RegionPicker";
import {
	loginRequest,
	graphConfig,
	fetchAuthRegionOrNull,
	storeAuthRegion,
	clearAuthRegionStorage,
} from "../authConfig";
import { useFetchRegionByShortcode } from "../endpoints";
import { callMsGraph } from "../graph";

import "../styles/SignIn.css";

export const SignIn = ({ language }) => {
	const { instance, accounts, inProgress } = useMsal();
	const { desiredRedirect } = useParams();
	const suggestedRegion = useFetchRegionByShortcode(desiredRedirect);
	const [authRegion, setAuthRegion] = useState(fetchAuthRegionOrNull());
	const [redirectUrl, setRedirectUrl] = useState(null);

	const setRegionAndReload = (r) => {
		storeAuthRegion(r);
		window.location.reload();
	};

	useEffect(() => {
		if (!authRegion && suggestedRegion) {
			setRegionAndReload(suggestedRegion);
		}
	}, [authRegion, suggestedRegion]);

	useEffect(() => {
		if (authRegion) {
			if (!redirectUrl) {
				if (accounts?.length) {
					resolveRedirect(instance, accounts[0], authRegion, desiredRedirect, setRedirectUrl);
				} else if (instance && inProgress === InteractionStatus.None) {
					storeAuthRegion(authRegion);
					try {
						instance.loginRedirect({
							...loginRequest(authRegion.adId, authRegion.groupId),
						});
					} catch (error) {
						// eslint-disable-next-line no-console
						console.error(`LOGIN ERROR: ${JSON.stringify(error || "{}")}`);
						clearAuthRegionStorage();
						setAuthRegion(null);
					}
				}
			}
		} else if (accounts?.length) { // rare cases
			instance.logoutRedirect({
				account: accounts[0],
				postLogoutRedirectUri: "/taqa-h2/",
				mainWindowRedirectUri: "/taqa-h2/",
			});
		}
	}, [authRegion, accounts, instance, redirectUrl, inProgress, desiredRedirect]);

	useEffect(() => {
		if (authRegion && inProgress === InteractionStatus.Logout) {
			clearAuthRegionStorage();
			setAuthRegion(null);
		}
	}, [inProgress, authRegion]);

	return (
		<>
			<AuthenticatedTemplate>
				{redirectUrl
					? <Redirect to={redirectUrl} />
					: <></>}
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				{!authRegion
					? <RegionPicker language={language} setRegion={(r) => setRegionAndReload(r)} />
					: <button className="spinner" type="button"> </button>}
			</UnauthenticatedTemplate>
		</>
	);
};

async function resolveRedirect(msal, account, authRegion, desiredRedirect, setRedirectUrl) {
	let fallbackRedirect = "no-access";
	try {
		const tokenResponse = await msal.acquireTokenSilent({ account });
		const myGroupsResponse = await callMsGraph(tokenResponse.accessToken, graphConfig.graphMeGroups("id"));
		const hasAccessToGroup = myGroupsResponse.value.find((group) => group.id === authRegion.groupId);
		if (!hasAccessToGroup) {
			setRedirectUrl(`/taqa-h2/${authRegion.shortcode}/no-access`);
			return;
		}

		if (!!desiredRedirect && desiredRedirect !== "admin") {
			setRedirectUrl(`/taqa-h2/${authRegion.shortcode}/${desiredRedirect}`);
			return;
		}

		fallbackRedirect = "participant";

		const query = graphConfig.graphMeOwnedObjects("id");
		const ownedObjectsResponse = await callMsGraph(tokenResponse.accessToken, query);
		const hasAdminAccessToGroup = ownedObjectsResponse.value.find((object) =>
			// eslint-disable-next-line implicit-arrow-linebreak,comma-dangle
			`${object.id}` === authRegion.groupId
			// eslint-disable-next-line function-paren-newline
		);
		if (hasAdminAccessToGroup) {
			setRedirectUrl(`/taqa-h2/${authRegion.shortcode}/${desiredRedirect || "admin"}`);
			return;
		}
		setRedirectUrl(`/taqa-h2/${authRegion.shortcode}/${fallbackRedirect}`);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
		setRedirectUrl(`/taqa-h2/${authRegion.shortcode}/${fallbackRedirect}`);
	}
}
