import { AuthError } from "@azure/msal-common";
import { graphConfig } from "./authConfig";

export class MsGraphError {
	constructor(error) {
		this.error = error;
	}
}

function msGraphRequest(accessToken, endpoint) {
	const headers = new Headers();
	const bearer = `Bearer ${accessToken}`;

	headers.append("Authorization", bearer);
	headers.append("ConsistencyLevel", "eventual");

	const options = {
		method: "GET",
		headers,
	};

	return fetch(endpoint, options);
}

export async function verifyAuth(accessToken) {
	const graphRequest = msGraphRequest(accessToken, graphConfig.graphMyIdEndpoint());

	return graphRequest
		.then((response) => {
			if (response.status === 200) {
				return response.json();
			}
			throw new AuthError("login_required", "Unknown error while checking Me/Id");
		}).catch((error) => {
			throw new AuthError("login_required", `Error while checking Me/Id: ${error.errorCode}`);
		});
}

export async function callMsGraph(accessToken, endpoint) {
	return msGraphRequest(accessToken, endpoint)
		.then((response) => response.json())
		.catch((error) => {
			if (error.errorCode === "consent_required"
				|| error.errorCode === "interaction_required"
				|| error.errorCode === "login_required") {
				throw new AuthError(error.errorCode, error.errorMessage, error.suberror);
			}
			throw new MsGraphError(error);
		});
}
