import React, { useContext, useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import ReactPaginate from "react-paginate";
import { UserItem } from "./UserItem";
import { DateRangePicker } from "./DateRangePicker";
import "../../styles/admin/UserTable.css";
import Download from "./Download";
import DownloadSurveys from "./DownloadSurveys";
import { PageCacheContext, useFetchFilteredUsers } from "../../endpoints";

export const UserTable = ({
	scaleColors,
	users,
	setsearchValue,
	setCurrentPage,
	text,
	lang,
	integers,
	setRedirectTo,
}) => {
	const pageCache = useContext(PageCacheContext);
	const [filterValue, setFilterValue] = useState({ timeFrom: false, timeTo: false });
	const {
		filteredUsers,
		fetchingData,
	} = useFetchFilteredUsers(setRedirectTo, filterValue, users, pageCache.accessToken);

	return (
		<div className="userTable" data-nousers={!filteredUsers?.length}>
			<Grid container className="userTable_inner">
				<Grid container className="userTable_tools" item xs={12}>
					<Grid className="userTable_tool" item xs={9}>
						<div className="userTable_tool_inner">
							<input
								id="userTable_field_search"
								className="userTable_tool_field"
								onChange={(data) => setsearchValue(data.target.value)}
								placeholder={text ? text[7] : ""}
							/>
						</div>
						<div className="userTable_tool_inner">
							<DateRangePicker
								setFilterValue={setFilterValue}
								text={text}
								lang={lang}
								integers={integers}
							/>
						</div>
					</Grid>
					<Grid className="userTable_tool" item xs={3}>
						<Download
							text={text}
							users={filteredUsers}
							fetchingData={fetchingData}
							filterValue={filterValue}
						/>
						<DownloadSurveys
							text={text}
							users={filteredUsers}
							fetchingData={fetchingData}
							filterValue={filterValue}
						/>
					</Grid>
				</Grid>
				<Grid container className="userTable_head" item xs={12}>
					<Grid className="userTable_heading" item xs={3}><h4>{`${text ? text[19] : <></>} (${filteredUsers.length})`}</h4></Grid>
					<Grid className="userTable_heading" item xs={2}><h4>{text ? text[20] : <></>}</h4></Grid>
					<Grid className="userTable_heading" item xs={3}><h4>{text ? text[21] : <></>}</h4></Grid>
					<Grid className="userTable_heading" item xs={2}><h4>{text ? text[22] : <></>}</h4></Grid>
					<Grid className="userTable_heading" item xs={2}><h4>{text ? text[23] : <></>}</h4></Grid>
				</Grid>
				<Grid className="userTable_list" item xs={12}>
					{
						fetchingData ? <CircularProgress /> : Object.keys(filteredUsers).map((key) => (
							<UserItem
								key={key}
								text={text}
								lang={lang}
								integers={integers}
								userData={filteredUsers[key].userData}
								sessions={filteredUsers[key].sessions}
								scaleColors={scaleColors}
							/>
						))
					}
				</Grid>
				<Grid className="userTable_page" item xs={12}>
					<div className="userTable_pagination">
						<ReactPaginate
							previousLabel="<"
							nextLabel=">"
							breakLabel="..."
							breakClassName="userTable_page_break"
							pageCount={pageCache.buffer.length}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={(data) => setCurrentPage(data.selected)}
							containerClassName="userTable_page_container"
							activeClassName="userTable_page_active"
						/>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};
