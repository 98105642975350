export class GraphUserModel {
	businessPhones = null;

	department = null;

	mail = null;

	employeeId = null;

	displayName = null;

	givenName = null;

	jobTitle = null;

	mail = null;

	mobilePhone = null;

	officeLocation = null;

	preferredLanguage = null;

	surname = null;

	userPrincipalName = null;

	id = null;
}
