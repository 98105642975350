import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../styles/NavBar.css";

export const PageLayout = ({
	children,
	username,
	navitems,
}) => (
	<>
		<div className="navBar">
			<div className="navBar_inner">
				<div className="navBar_icon">
					<img alt="icon" src="/taqa-h2/taqa.jpg" />
				</div>
				<div className="navBar_username">
					<h5 className="card-title">{username}</h5>
				</div>
				<Dropdown>
					<Dropdown.Toggle variant="success" id="dropdown-basic">
						<img alt="" src="/taqa-h2/userbtn.png" />
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{navitems}
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</div>
		{children}
	</>
);
