import React, { useState } from "react";
import { Grid, LinearProgress } from "@mui/material";
import "../../styles/admin/UserItem.css";

export const UserItem = ({
	userData,
	sessions,
	integers,
	scaleColors,
	lang,
	text,
}) => {
	const [isOpen, setOpen] = useState(null);
	const [openPopup, setOpenPopup] = useState("");

	return sessions.map((session, index) => {
		const mins = Math.floor(session.duration / 60);

		let score = `${((Math.round(session.score / 25) * 100))}`;
		if (lang && integers) integers[lang].forEach((i) => { score = score.replaceAll(i[0], i[1]); });

		const progressInt = (session.progress * 90) + (session.survey * 5) + (session.attestation * 5);
		let progress = !progressInt ? "0%" : `${Math.round(progressInt)}%`;
		if (lang && integers) integers[lang].forEach((i) => { progress = progress.replaceAll(i[0], i[1]); });

		let duration = `${mins} : ${Math.round(session.duration - mins * 60)}`;
		if (lang && integers) integers[lang].forEach((i) => { duration = duration.replaceAll(i[0], i[1]); });

		let totalscore = `${Math.round(session.score)}`;
		if (lang && integers) integers[lang].forEach((i) => { totalscore = totalscore.replaceAll(i[0], i[1]); });

		return (
			<div
				key={`${userData.hash} ${session.date}`}
				data-date={session.date}
				data-index={index}
				className="userItem"
				data-open={isOpen}
				data-popupopen={openPopup}
				text={text}
			>
				<Grid container className="userItem_inner">
					<Grid className="userItem_field" item xs={3}>
						<p>
							<img className="avatar" src="/taqa-h2/avatar.png" alt={`${userData.displayName} profile img`} />
							{userData.displayName}
						</p>
					</Grid>
					<Grid className="userItem_field" item xs={2}>
						<p>{session.date ? new Date(session.date).toLocaleDateString((text ? text[6] : "default")) : "-"}</p>
					</Grid>
					<Grid className="userItem_field" item xs={3}>
						<div className="resultBar">
							<span>
								<p>
									{progress}
								</p>
							</span>
							<div className="dividerContainer">
								<span className="dividers">&nbsp;</span>
								<span className="dividers">&nbsp;</span>
								<span className="dividers">&nbsp;</span>
							</div>
							<LinearProgress
								style={{ backgroundColor: scaleColors.return(progressInt) }}
								variant="determinate"
								value={progressInt}
							/>
						</div>
					</Grid>
					<Grid className="userItem_field" item xs={2}>
						<p>{duration}</p>
					</Grid>
					<Grid className="userItem_field" item xs={1}>
						<p>
							<i style={{ color: scaleColors.return(progressInt) }}>
								{totalscore}
							</i>
						</p>
					</Grid>
					<Grid className="userItem_field" item xs={1}>
						{
							(index === 0) ? (
								<div className="iconContainer" data-open={openPopup}>
									<div className="userItem_popup_container">
										<button className="button" type="button" onClick={() => setOpenPopup("details")}>
											<img src="/taqa-h2/kebab.png" alt="" />
										</button>
										<input className="userTable_popup_bg" onClick={() => setOpenPopup("")} />
										<div className="userItem_popup_details">
											<button type="button" className="userTable_popup_close" onClick={() => setOpenPopup("")}>
												<i style={{ backgroundImage: "url(/taqa-h2/exit.png)" }} />
											</button>
											<span>{userData.displayName}</span>
											<span>{userData.mail || userData.userPrincipalName}</span>
										</div>
									</div>
									<button className="button" type="button" onClick={() => setOpen(!isOpen)}>
										<img className="arrow" src={sessions.length > 1 ? "/taqa-h2/arrow.png" : ""} alt="" />
									</button>
								</div>
							) : <></>
						}
					</Grid>
				</Grid>
			</div>
		);
	});
};
